import { defineStore } from "pinia"
import { service } from "./service"

export const useStore = defineStore({
    id: "useCustomerDeacivateStore",
    state: () => ({
        loginResponse: null,
        deactivateResponse: null,
        token: localStorage.getItem("userToken") || null,
        userData: localStorage.getItem("userData")
            ? JSON.parse(localStorage.getItem("userData"))
            : null
    }),

    getters: {
        getLoginResponse(state) {
            return state.loginResponse
        },
        getDeactivateResponse(state) {
            return state.deactivateResponse
        },
        getToken(state) {
            return state.token
        },
        getUserData(state) {
            return JSON.parse(state.userData)
        },
        isAuth(state) {
            return state.token ? true : false
        }
    },

    actions: {
        async login(params) {
            const response = await service.login(params)

            const userData = {
                tenant: response.tenant,
                customer_id: response.customer.perx_customer_id,
                identifier: response.customer.identifier
            }
            this.token = await response.bearer_token
            this.userData = await userData
            localStorage.setItem("userToken", response.bearer_token)
            localStorage.setItem("userData", JSON.stringify(userData))

            this.loginResponse = response
        },
        async deactivate(params) {
            const response = await service.deactivate(params)
            this.deactivateResponse = response

            if (response) {
                this.token = null
                this.userData = null
                localStorage.removeItem("userToken")
                localStorage.removeItem("userData")
            }
        }
    }
})
