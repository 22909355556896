import axios from "axios"
import EventBus from "@/libs/AppEventBus"
import JSONbig from "json-bigint"
import { useStore } from "@/modules/deactivate/store"
import router from "@/routers"

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest"
    }
})

api.interceptors.request.use(function (config) {
    const store = useStore()
    const token = store.getToken
    config.headers.common["Authorization"] = `Bearer ${token}`
    config.transformResponse = (data) => data
    return config
})

api.interceptors.response.use(
    (res) => {
        res.data = JSONbig.parse(res.data)

        return res
    },
    (err) => {
        if (err.response) {
            console.log(err)
            if (err.response.status === 401) {
                showToast("Invalid Crenditials!")
                const store = useStore()
                store.logout()
                router.push({ name: "login" })
            } else if (err.response.status === 403) {
                router.push({ name: "not-authorized" })
            } else if (err.response.status === 422) {
                return Promise.reject(err.response)
            } else if (err.response.status === 404) {
                showToast(err.message)
            }
            showToast(err.message.data.message)
        } else if (err.request) {
            showToast(err.message)
        } else {
            showToast(err.message)
        }
    }
)

function showToast(message) {
    /**
     * Can see EventBus in AppWrapper(Top Component)
     */
    EventBus.emit("show-toast", {
        severity: "error",
        summary: "",
        detail: message
    })
}

export default api
