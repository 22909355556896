import api from "@/libs/axios"

const endpoints = {
    path: "login",
    deactivate_path: "deactivate"
}

const service = {
    deactivate: async (params) => {
        const result = await api.post(`${endpoints.deactivate_path}`, params)
        return result.data
    },
    login: async (params) => {
        const result = await api.post(endpoints.path, params)
        return result.data
    }
}

export { service }
