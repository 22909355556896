<template>
    <div class="customer-deactivate">
        <top-bar />
        <div class="p-5 m-auto" style="max-width: 400px; height: 90vh">
            <router-view />
        </div>
    </div>
</template>

<script>
import TopBar from "./TobBar.vue"
export default {
    components: { TopBar }
}
</script>

<style></style>
