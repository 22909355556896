const deactivateRoutes = [
    {
        path: "/",
        name: "customerDeactivate",
        component: () => import("@/modules/deactivate/entry/New.vue"),
        meta: {
            subject: "users",
            action: "read",
            layout: "full"
        }
    },
    {
        path: "/customer-delete/terms-condition",
        name: "termCondition",
        component: () => import("@/modules/deactivate/list/Tac.vue"),
        meta: {
            subject: "users",
            action: "read",
            layout: "full",
            requireAuth: true
        }
    },
    {
        path: "/customer-delete/success",
        name: "deactivateSuccess",
        component: () => import("@/modules/deactivate/list/Success.vue"),
        meta: {
            subject: "users",
            action: "read",
            layout: "full"
        }
    }
]

export default deactivateRoutes
