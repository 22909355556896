export const initialAbility = [
  {
    action: "read",
    subject: "users"
  },
  {
    action: "edit",
    subject: "users"
  },
  {
    action: "create",
    subject: "users"
  },
  {
    action: "delete",
    subject: "users"
  },
  {
    action: "read",
    subject: "home"
  }
]

export const _ = undefined
